import React from "react";
import { Button, Modal } from "antd";
import "./modals.scss";

export default function DeclineAppointmentModal({ id, visible, headingText, onClose, acceptorDeclineHandler }) {
  const onDecline = (e) => {
    onClose(e);
    acceptorDeclineHandler(id, false);
  };

  return (
    <Modal
      centered
      width={576}
      header={null}
      footer={null}
      open={visible}
      onCancel={onClose}
      onOk={onClose}
      className="common-modal decline-modal settings-service-modal"
      closable
      closeIcon={<img alt={""} src={"/assets/icons/close-icon.svg"} />}
    >
      <div className="row">
        <div className="col-12 crossImage common-div-modal text-center">
          <img alt={""} src={"/assets/icons/decline-info-icon.svg"} />
          <h3>{headingText}</h3>
          <ul className="mb-0 list-inline">
            <li>
              <Button className="no-gradient button-common" onClick={onClose}>
                No
              </Button>
            </li>
            <li>
              <Button
                style={{ marginLeft: "3px" }}
                className="bg-transparent p-0 shadow-none confirm-logout-btn"
                onClick={onDecline}
              >
                Yes
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
