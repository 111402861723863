import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../common/helper/layout";
import {
  daysArr,
  generateNext90DatesFromPreviousWeek,
  generateNext90DatesFromSelectedDays,
  getDate,
  getDay,
  formatDate,
  formatDateISO,
  getFormattedList,
} from "../../../utils";
import { DownOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import "./availability.scss";
import { Button, Checkbox, Divider, Modal, TimePicker, message } from "antd";
import "../../../styles/form.scss";
import dayjs from "dayjs";
import Footer from "../../../common/helper/footer/footer";
import { getPayload, getSelectedDate } from "./helper";

const nextNinetyDays = generateNext90DatesFromPreviousWeek();

export default function ProviderAvailability(props) {
  return (
    <Layout>
      <Availability {...props} from="edit" />
    </Layout>
  );
}

export const Availability = ({ settingPage, from }) => {
  const [currentShowingDates, setCurrentShowingDates] = useState(nextNinetyDays.slice(0, 15));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const [availabilityModalVisible, setAvailabilityVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [activeDate, setActiveDate] = useState("");
  const [endTime, setEndTime] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [enable, setEnable] = useState(false);
  const [presSelected, setPreSelected] = useState([]);
  const [availableAllDay, setAvailableAllDay] = useState(false);

  const navigate = useNavigate();

  const selectedDayHAndler = (day) => {
    setSelectedDay((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((selectedDay) => selectedDay !== day)
        : [...prevSelectedDays, day]
    );
  };

  const nextMonthHandler = () => {
    if (count + 15 <= 90) {
      setCount(count + 15);
      const updateList = nextNinetyDays.slice(count + 15, count + 30);
      setActiveDate(updateList[0]);
      setCurrentShowingDates(updateList);
    }
  };

  const prevMonthHandler = () => {
    if (count > 0) {
      const updateCount = count - 15 < 0 ? 0 : count - 15;
      setCount(updateCount);
      const updateList = nextNinetyDays.slice(updateCount, updateCount + 15);
      setActiveDate(updateList[0]);
      setCurrentShowingDates(updateList);
    }
  };

  const onClickDateHandler = (dateItem) => {
    setActiveDate(dateItem);

    if (count <= 90) {
      const ind = nextNinetyDays.findIndex((item) => item == dateItem);
      if (ind >= 0) {
        setCount(ind);
        setCurrentShowingDates(nextNinetyDays.slice(ind, ind + 15));
      }
    }
  };

  useEffect(() => {
    getFinalSelectedDates();
  }, []);

  useEffect(() => {
    if (startTime && endTime && selectedDate) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [startTime, endTime]);

  const getFinalSelectedDates = () => {
    axiosInstance
      .post("/business/availability/list")
      .then((res) => {
        if (res.data?.output?.length) {
          setEnable(true);
          const list = getFormattedList(res.data?.output);
          setPreSelected(list);
        }
      })
      .catch((err) => {
        message.error(err?.data?.error?.message);
      });
  };

  const allDaysSelected = (e) => {
    setAvailableAllDay(e.target.checked);

    if (e.target.checked) {
      let startD = new Date(selectedDate).setHours(0, 0, 0);
      startD = formatDateISO(startD);
      let endD = new Date(selectedDate).setHours(23, 59, 0);
      endD = formatDateISO(endD);
      setStartTime(startD);
      setEndTime(endD);
    } else {
      setStartTime("");
      setEndTime("");
    }
  };

  const handleStartTimeChange = (time) => {
    const date = time.toDate();
    const iso = formatDateISO(date);
    setStartTime(iso);
  };

  const handleEndTimeChange = (time) => {
    const date = time.toDate();
    const iso = formatDateISO(date);
    setEndTime(iso);
  };

  const updateAvailability = (allDates, listUpdated) => {
    if (allDates?.length > 0 && startTime && endTime) setEnable(true);
    else setEnable(false);

    axiosInstance
      .post("/business/availability/add", { availibityDetails: listUpdated })
      .then(async (res) => {
        message.success("Availability added successfully");
        setAvailabilityVisible(false);
        getFinalSelectedDates();
        await axiosInstance.put("/business/updateProfile", { screen: from === "edit" ? 6 : 5 });
        localStorage.setItem("screen", from === "edit" ? 6 : 5);
      })
      .catch((err) => {
        message.error(err.data?.error?.message);
      });
  };

  const addAvailabilityHandler = () => {
    const { availabilityDetails, sortedList } = getPayload(
      startTime,
      endTime,
      isEdit,
      selectedDate,
      selectedDay,
      presSelected
    );
    updateAvailability(availabilityDetails, sortedList);
  };

  return (
    <>
      <div className="availability-container">
        {!settingPage && (
          <>
            <h4 className="text-center fw-bold availability-heading my-3">Add your availability</h4>
            <hr />
          </>
        )}
        <div className={settingPage ? "availability-wrapper edit" : "availability-wrapper"}>
          <div className="datepicker-wrapper">
            <div className="month-wrapper">
              <img src={"/assets/icons/left-arrow.svg"} onClick={prevMonthHandler} style={{ cursor: "pointer" }} />
              <p className="month-label">{formatDate(new Date(currentShowingDates[0]), "MMMM YYYY")}</p>
              <img src={"/assets/icons/right-arrow.svg"} onClick={nextMonthHandler} style={{ cursor: "pointer" }} />
            </div>

            <div className="datepicker d-flex justify-content-center overflow-x-auto w-100">
              <img src={"/assets/icons/left-arrow.svg"} onClick={prevMonthHandler} style={{ cursor: "pointer" }} />
              {currentShowingDates.map((dateItem) => {
                const dateObj = new Date(dateItem);
                const active = activeDate || currentShowingDates[0];
                return (
                  <div
                    className={active == dateItem ? "datepicker__item__active" : "datepicker__item__inactive"}
                    onClick={() => onClickDateHandler(dateItem)}
                  >
                    <span className="datepicker__item__day">{daysArr[getDay(dateObj)].slice(0, 3)}</span>
                    <span className="datepicker__item__date">{getDate(dateObj)}</span>
                  </div>
                );
              })}
              <img src={"/assets/icons/right-arrow.svg"} onClick={nextMonthHandler} style={{ cursor: "pointer" }} />
            </div>
          </div>

          <div className="date-listing">
            {currentShowingDates.slice(0, 4).map((dateItem, index) => {
              const dateObj = new Date(dateItem);
              const filteredArr = presSelected?.find((date) => {
                const date1 = formatDate(new Date(date.date), "YYYY-MM-DD");
                const date2 = formatDate(dateObj, "YYYY-MM-DD");
                return date1 == date2;
              });

              return (
                <div className={index === 4 ? "pb-4 date-item" : "date-item"}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="current-date">
                      {daysArr[getDay(dateObj)]} {formatDate(dateObj, "MMMM DD ")}
                    </h6>
                  </div>
                  <Divider />

                  {filteredArr ? (
                    <div>
                      {[filteredArr].map((obj) => {
                        return (
                          <div className="add-edit-time-wrapper edit">
                            <h6 className="time-slot">
                              {formatDate(new Date(obj.startTime), "hh:mm A")} -{" "}
                              {formatDate(new Date(obj.endTime), "hh:mm A")}
                            </h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="add-text"> </p>
                              <img
                                alt="edit-icon"
                                src="/assets/icons/edit-square.svg"
                                width={22}
                                onClick={() => {
                                  const date = getSelectedDate(dateObj);
                                  setSelectedDate(date);
                                  setStartTime(obj.startTime);
                                  setEndTime(obj.endTime);
                                  setisEdit(true);
                                  setAvailabilityVisible(true);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="add-edit-time-wrapper">
                      <h6 className="time-slot"></h6>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="add-text">Add Availability </p>
                        <img
                          alt="plus-icon"
                          src="/assets/icons/plus-icon.svg"
                          width={30}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setStartTime("");
                            setEndTime("");
                            const date = getSelectedDate(dateObj);
                            setSelectedDate(date);
                            setisEdit(false);
                            setSelectedDay([]);
                            setAvailabilityVisible(true);
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>
        {!settingPage && (
          <div className="availability-footer d-flex justify-content-between align-items-center px-5 my-1">
            <div className="d-flex  ">
              <Footer from={true} />
            </div>
            <div className="right">
              <button
                className={enable ? "active " : ""}
                disabled={!enable}
                onClick={() =>
                  navigate("/services/top-listing", {
                    state: { fromPage: "createProfile" },
                  })
                }
              >
                Get Started
              </button>
            </div>
          </div>
        )}
      </div>

      <AvailabilityModal
        availabilityModalVisible={availabilityModalVisible}
        handleCloseAvailabilityModal={() => setAvailabilityVisible(false)}
        selectedDate={selectedDate}
        selectedDayHAndler={selectedDayHAndler}
        allDaysSelected={allDaysSelected}
        availableAllDay={availableAllDay}
        setAvailableAllDay={setAvailableAllDay}
        handleStartTimeChange={handleStartTimeChange}
        handleEndTimeChange={handleEndTimeChange}
        endTime={endTime}
        isEdit={isEdit}
        startTime={startTime}
        selectedDay={selectedDay}
        generateNext90DatesFromPreviousWeek={generateNext90DatesFromPreviousWeek}
        generateNext90DatesFromSelectedDays={generateNext90DatesFromSelectedDays}
        addAvailabilityHandler={addAvailabilityHandler}
        disabled={disabled}
      />
    </>
  );
};

const AvailabilityModal = (props) => {
  const {
    availabilityModalVisible,
    handleCloseAvailabilityModal,
    selectedDate,
    selectedDayHAndler,
    selectedDay,
    allDaysSelected,
    availableAllDay,
    setAvailableAllDay,
    handleStartTimeChange,
    handleEndTimeChange,
    addAvailabilityHandler,
    disabled,
    startTime,
    endTime,
    isEdit,
  } = props;

  useEffect(() => {
    if (startTime && endTime) {
      const startDate = new Date(startTime);
      // Do your operations
      const endDate = new Date(endTime);
      const seconds = (endDate.getTime() - startDate.getTime()) / 1000 / 86340;
      if (seconds === 1) setAvailableAllDay(true);
    }
    if (!availabilityModalVisible) setAvailableAllDay(false);
  }, [availabilityModalVisible]);

  const disabledHours = (key) => {
    const isToday = new Date().getDate() === new Date(selectedDate).getDate();
    const currentStartHour = isToday ? new Date().getHours() : startTime ? new Date(startTime).getHours() : 0;
    const currentEndHour = startTime ? new Date(startTime).getHours() : isToday ? new Date().getHours() : 0;
    const hours = Array.from(Array((key === "startTime" ? currentStartHour : currentEndHour) + 1).keys());

    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const isToday = new Date().getDate() === new Date(selectedDate).getDate();
    const minutes =
      selectedDay.length > 0 && isToday ? Array.from(Array(selectedHour > currentHour ? 0 : currentMinute).keys()) : [];
    return minutes;
  };

  return (
    <Modal
      className="availability-modal-container"
      open={availabilityModalVisible}
      onCancel={handleCloseAvailabilityModal}
      footer={null}
      closable
      closeIcon={<img alt={""} src={"/assets/icons/close-icon.svg"} width={40} />}
    >
      <div className="d-flex justify-content-center">
        <h3 className="modal-title">Add your availability</h3>
      </div>

      <div className="date-time-wrapper">
        <p className="selected-date">
          {daysArr[getDay(selectedDate)]} {formatDate(selectedDate, "MMMM DD")}
        </p>

        <p className="repeat-txt">Repeat On</p>

        <div className="day-tag-wrapper">
          {daysArr.map((dayItem, index) => {
            const day = dayItem[0];
            const selected = selectedDay.includes(dayItem);

            return (
              <span
                key={index}
                className={selected ? "day-tag-selected" : "day-tag"}
                onClick={() => selectedDayHAndler(dayItem)}
                style={{
                  background: selected ? "#ffffff" : "#asdgsa",
                  color: selected ? "rgba(255, 255, 255, 0.8)" : "", // Change this to your desired color
                  cursor: "pointer",
                }}
              >
                {day}
              </span>
            );
          })}
        </div>

        <div className="timepicker-wrapper">
          <TimePicker
            use12Hours
            format={"hh:mm A"}
            showNow={false}
            onChange={handleStartTimeChange}
            suffixIcon={<DownOutlined />}
            placeholder="Start Time"
            allowClear={false}
            disabledTime={() => {
              return {
                disabledHours: () => disabledHours("startTime"),
              };
            }}
            value={startTime ? dayjs(startTime) : ""}
          />

          <TimePicker
            use12Hours
            format={"hh:mm A"}
            showNow={false}
            onChange={handleEndTimeChange}
            suffixIcon={<DownOutlined />}
            placeholder="End Time"
            allowClear={false}
            disabledTime={() => {
              return {
                disabledHours: () => disabledHours("endTime"),
              };
            }}
            value={endTime ? dayjs(endTime) : ""}
          />
        </div>

        <div className="d-flex align-items-center my-4 circular-checkbox">
          <Checkbox checked={availableAllDay} onChange={allDaysSelected} />{" "}
          <span className="mx-2">Available All Day</span>
        </div>

        <div className="d-flex justify-content-center">
          <Button
            className="submit-btn"
            disabled={disabled}
            onClick={addAvailabilityHandler}
            style={{
              background: disabled ? "#B9CDD9" : "linear-gradient(135deg, #02e09e 0%, #66b6fa 100%)",
            }}
          >
            {isEdit ? "Save" : "Next"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
