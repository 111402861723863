import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import UploadImage from "./UploadImage";
import { axiosInstance } from "../../../../controller/axiosInterceptor";
import "../../../../styles/form.scss";
import SpinWrapper from "../../../../common/Loader/SpinWrapper";

const SelectServices = (props) => {
  const serviceObj = { title: "", price: "", imgUrl: "", description: "" };
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [loader, setLoader] = useState(false);

  const {
    categoryValue,
    setCategoryValue,
    addedServices,
    setAddedServices,
    disabled,
    setDisabled,
    onlyTextHandler,
    onlyNumbersHandler,
  } = props;


  useEffect(() => {
    if (addedServices?.length === 0) {
      setDisabled(true);
    } else {
      const isFilled = addedServices?.every(
        (i) => i.title && i.subCatId && i.price && i.description && i.imgUrl !== ""
      );
      setDisabled(!isFilled);
    }

    getServices();
  }, []);

  const getServices = () => {
    axiosInstance
      .post("/admin/category/list", { page: 1, pageLimit: 10 })
      .then((resp) => {
        const modifiedArr = resp.data.output.list.map((i) => ({
          value: i._id,
          label: i.name,
        }));
        setCategoryList(modifiedArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (categoryValue) {
      getSubCategories();
    }
  }, [categoryValue]);

  const getSubCategories = () => {
    axiosInstance
      .post("/admin/subcategory/list", {
        categoryId: categoryValue,
        page: 1,
        pageLimit: 10,
      })
      .then((resp) => {
        const modifiedArr = resp.data.output.list.map((i) => ({
          value: i._id,
          label: i.name,
        }));
        setSubCategoryList(modifiedArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddMore = () => {
    setDisabled(true);
    const clonedArr = [...addedServices];
    clonedArr.push(serviceObj);
    setAddedServices(clonedArr);
  };

  const handleFormChange = () => {
    if (addedServices[addedServices.length - 1].catName) {
      setDisabled(true);
    }
    if (props.form.getFieldValue().firstName !== "") {
    }
    if (props.form.getFieldValue()) {
      setDisabled(true);
    }
    const hasErrors =
      !props.form.isFieldsTouched(true) ||
      props.form.getFieldsError().filter(({ errors }) => errors.length).length >
        0;
    setDisabled(hasErrors);
  };

  const handleUpdateServicesArr = (key, value, idx) => {
    setDisabled(true);
    const clonedArr = [...addedServices];
    clonedArr[idx][key] = value;
    setAddedServices(clonedArr);
    if (
      (clonedArr[clonedArr.length - 1].title,
      clonedArr[clonedArr.length - 1].subCatId &&
        clonedArr[clonedArr.length - 1].price &&
        clonedArr[clonedArr.length - 1].description)
    ) {
      setDisabled(false);
    }
  };

  const handleDeleteService = async (ind) => {
    if (addedServices?.length > 1) {
      let newList = [...addedServices];
      newList = newList.filter((it, index) => index !== ind);
      setAddedServices(newList);
      if (
        (newList[newList.length - 1].title,
        newList[newList.length - 1].subCatId &&
          newList[newList.length - 1].price &&
          newList[newList.length - 1].description)
      ) {
        setDisabled(false);
      }
    }
  };

  return (
    <SpinWrapper loader={loader}>
      <div className="h-100">
        <div className="row">
          <div className="col-12 text-center steps-form-heading">
            <h5>Select Services</h5>
          </div>
        </div>
        <div className="h-100">
          <div className="row common-form-div form-create-profile">
            <Form
              initialValues={{ remember: true }}
              autoComplete="off"
              form={props.form1Ref}
              onFieldsChange={handleFormChange}
            >
              <div className="col-12">
                <Form.Item
                  className="position-relative"
                  label={false}
                  rules={[
                    {
                      required: true,
                      message: "Please select your Service Category!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src="/assets/icons/chevron-down-black.svg"
                        alt=""
                        className="img-fluid"
                        style={{ marginTop: "30px" }}
                      />
                    }
                    placeholder="Select Category"
                    options={categoryList}
                    value={categoryValue}
                    onChange={(value) => {
                      setCategoryValue(value);
                      if (addedServices.length) {
                        const clonedArr = addedServices.map((i) => ({
                          ...i,
                          subCatId: "",
                        }));
                        setAddedServices(clonedArr);
                      }
                    }}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
        <div className="scroll-services">
          {categoryValue &&
            addedServices.map((i, idx) => {
              return (
                <div className="row m-0">
                  <div className="col-12 common-form-div form-create-profile p-0">
                    <Form
                      initialValues={{ remember: true }}
                      onFieldsChange={handleFormChange}
                      autoComplete="off"
                    >
                      <div className="row m-0">
                        <div className="col-12 p-0">
                          <div className="row">
                            <div className="col-12 col-sm-12 text-center col-md-3">
                              <UploadImage
                                handleUpdateServicesArr={
                                  handleUpdateServicesArr
                                }
                                idx={idx}
                                IS_SERVICES
                                currentImage={i?.imgUrl}
                                setLoader={setLoader}
                              />
                            </div>
                            <div className="col-12 col-sm-12 col-md-9">
                              <div className="row">
                                <div className="col-7">
                                  <Form.Item
                                    className="position-relative"
                                    label={false}
                                    rules={[
                                      {
                                        required: true,
                                        message: "This field is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Service title"
                                      onChange={(e) =>
                                        handleUpdateServicesArr(
                                          "title",
                                          e.target.value,
                                          idx
                                        )
                                      }
                                      onKeyPress={onlyTextHandler}
                                      maxLength={36}
                                      value={i.title}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="col-5">
                                  <Form.Item
                                    className="position-relative"
                                    label={false}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please select your Sub Category!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      suffixIcon={
                                        <img
                                          src="/assets/icons/chevron-down-black.svg"
                                          alt=""
                                          className="img-fluid"
                                          style={{ marginTop: "30px" }}
                                        />
                                      }
                                      placeholder="Sub category"
                                      onChange={(value) =>
                                        handleUpdateServicesArr(
                                          "subCatId",
                                          value,
                                          idx
                                        )
                                      }
                                      value={i.subCatId || null}
                                      options={subCategoryList}
                                    />
                                  </Form.Item>
                                </div>
                                <div className={idx > 0 ? "col-10" : "col-12"}>
                                  <Form.Item
                                    className="position-relative"
                                    label={false}
                                    maxLength={4}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please input your service fee!",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Service fee"
                                      prefix="$"
                                      className="service-prefix"
                                      type="text"
                                      maxLength={4}
                                      onKeyPress={onlyNumbersHandler}
                                      onWheel={props.scrollEffectHandler}
                                      onChange={(e) =>
                                        handleUpdateServicesArr(
                                          "price",
                                          e.target.value,
                                          idx
                                        )
                                      }
                                      value={i?.price}
                                    />
                                  </Form.Item>
                                </div>
                                {idx > 0 ? (
                                  <div className="col-2 delete-service">
                                    <span
                                      onClick={() => handleDeleteService(idx)}
                                    >
                                      <img
                                        src="/assets/icons/deleteServiceIcon.svg"
                                        alt="delete-service"
                                        className="dlt-service-icon"
                                      />
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <Form.Item
                            className="position-relative"
                            label={false}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input your service description!",
                              },
                            ]}
                          >
                            <Input.TextArea
                              className="textarea-message textarea-description"
                              styles={{ height: "92px" }}
                              placeholder="Service description"
                              onChange={(e) =>
                                handleUpdateServicesArr(
                                  "description",
                                  e.target.value,
                                  idx
                                )
                              }
                              maxLength={255}
                              value={i?.description}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              );
            })}
        </div>
        {categoryValue && (
          <div className="row m-0">
            <div className="col-12" style={{ marginTop: "-4px" }}>
              <Button
                className={
                  disabled ? "common-btn ms-auto" : "common-btn-dis  ms-auto  "
                }
                style={{ maxWidth: "130px" }}
                onClick={handleAddMore}
                disabled={disabled}
              >
                Add More
              </Button>
            </div>
          </div>
        )}
      </div>
    </SpinWrapper>
  );
};

export default SelectServices;
