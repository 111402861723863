import { Button, Checkbox, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Footer from "../../../common/helper/footer/footer";
import ApproveAppointment from "../../../common/helper/modals/Approve";
import CustomMobileHeader from "../../../common/mobile/CustomMobileHeader";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import "../profile-components/create-profile.scss";

const monthlyFee = 149.99;
const serviceFee = (149.99 * 2.9) / 100 + 0.3;

export default function AddServices(props) {
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const [checkedServices, setCheckedServices] = useState([user?.businessName]);
  const [approveModal, setApproveModal] = useState(false);
  const location = useLocation();
  const listInnerRef = useRef();
  const navigate = useNavigate();
  const skipPage = location.state?.fromPage || "";

  const onClose = () => {
    setApproveModal(false);
  };

  return (
    <>
      <CustomMobileHeader />

      <div
        className={`container-fluid create-profile-main ${
          props.addnewClass ? "modal-fluid-main" : ""
        }`}
      >
        <div className="container">
          <div
            className={
              window.location.pathname === "/services/top-listing"
                ? "row create-profile-heading heading"
                : "row update-profile-heading heading"
            }
          >
            <div className="col-12 text-center">
              <h1>Add your business to top listing</h1>

              <p>Pay a monthly fee to opt a place in top 4 listing</p>
            </div>
          </div>

          <div
            className={
              window.location.pathname === "/services/top-listing"
                ? "row add-service-list-row"
                : "row edit-service-list-row"
            }
          >
            <div
              className={`col-12 col-sm-12 col-md-8 mx-auto ${
                props.addnewClass ? "col-md-12" : ""
              }`}
            >
              <div className="row mx-0 common-card-row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 text-center steps-form-heading">
                      <h5>Select Business</h5>
                    </div>
                  </div>

                  <div
                    className="row "
                    ref={listInnerRef}
                    style={{ height: "170px", overflow: "auto" }}
                  >
                    <div className="col-12 p-0 service-custom-checkbox">
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        value={[checkedServices]}
                        onChange={(e) => {
                          setCheckedServices(checkedServices);
                        }}
                      >
                        <div className="row w-100 m-0">
                          <div className="col-12 col-sm-12 col-md-6 px-2">
                            <Checkbox value={checkedServices}>
                              <div className="row w-100 h-100 align-items-center px-3">
                                <div className="col-12 d-flex align-items-center p-0">
                                  <div className="service-img-div">
                                    <img
                                      src={user?.profileImage}
                                      alt="service-images"
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="service-details">
                                    {user?.businessName}
                                  </div>
                                </div>
                              </div>
                            </Checkbox>
                          </div>
                        </div>
                      </Checkbox.Group>
                    </div>
                  </div>

                  <div className="row amount-details-row">
                    <div className="col-12">
                      <ul className="list-inline">
                        <li className="list-inline-item text-start">
                          Monthly Fee
                        </li>

                        <li className="list-inline-item text-end">
                          $ {monthlyFee.toFixed(2)}
                        </li>
                      </ul>

                      <ul className="list-inline">
                        <li className="list-inline-item text-start d-flex align-items-center">
                          Service Fee
                          <Tooltip title="This fee includes stripe fee.">
                            <img
                              alt={""}
                              src={"/assets/icons/info-icon.svg"}
                              style={{ marginLeft: "5px" }}
                            />
                          </Tooltip>
                        </li>

                        <li className="list-inline-item text-end">
                          $ {serviceFee.toFixed(2)}
                        </li>
                      </ul>

                      <ul className="list-inline total-amount-ul">
                        <li className="list-inline-item text-start">
                          Total Amount
                        </li>

                        <li className="list-inline-item text-end">
                          ${" "}
                          {(Number(serviceFee) + Number(monthlyFee)).toFixed(2)}
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 process-div text-center">
                      <Button
                        className={"common-btn-dis mx-auto auto"}
                        onClick={() =>
                          navigate("/services/top-listing/add-payments", {
                            state: {
                              checkedServices,
                              monthlyFee,
                              serviceFee,
                              fromPage: skipPage,
                            },
                          })
                        }
                      >
                        Proceed to Pay
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {props.addnewClass ? (
            <div></div>
          ) : (
            <div className="steps-action steps-action-custom container">
              <div className="row mx-0 h-100 align-items-center justify-content-between">
                <Footer from />
                {window.location.pathname == "/services/top-listing" ? (
                  <div className="w-auto p-0">
                    <Button
                      className="skip-btn"
                      onClick={async () => {
                        await axiosInstance.put("/business/updateProfile", {
                          screen: 6,
                        });

                        if (user?.isBusinessApproved === "Approved") {
                          localStorage.setItem("screen", 6);
                          navigate("/business-provider/dashboard");
                        } else setApproveModal(true);
                      }}
                    >
                      Skip for Now
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <ApproveAppointment
        visible={approveModal}
        visibleApproveAppointment={setApproveModal}
        onClose={onClose}
      />
    </>
  );
}
