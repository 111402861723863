import { useEffect, useState, useRef } from "react";
import { Button, Col, Row, Tabs, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { axiosInstance } from "../../../controller/axiosInterceptor";
import DeclineAppointmentModal from "../../../common/helper/modals/DeclineAppointmentModal";
import SpinWrapper from "../../../common/Loader/SpinWrapper";
import Layout from "../../../common/helper/layout";

import "./appointments.scss";

const tabsArr = [
  {
    key: "1",
    tabName: "Pending",
    value: ["Pending"],
  },
  {
    key: "2",
    tabName: "Upcoming",
    value: ["Upcoming", "Ongoing"],
  },
  {
    key: "3",
    tabName: "Past",
    value: ["Canceled", "Completed"],
  },
];

export default function AppointmentsDashboard() {
  const location = useLocation();

  const statusTab = location?.state?.statusTab;

  const [activeTab, setActiveTab] = useState("1");
  const [status, setStatus] = useState(statusTab ? statusTab : "Pending");
  const [appointmentsList, setAppointmentsList] = useState([]);
  const [visible, setVisible] = useState();
  const [acceptAppointment, setAcceptAppointment] = useState(false);
  const [hasMore, sethasMore] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [initial, setInitial] = useState(true);
  const listInnerRef = useRef();

  useEffect(() => {
    if (statusTab) {
      tabsArr.forEach((data) => {
        if (data.tabName == statusTab) {
          setActiveTab(data?.key);
          setStatus(statusTab);
        }
      });
    } else {
      setActiveTab("1");
      setStatus("Pending");
    }
  }, []);

  const handleTabChange = (e) => {
    setLoader(true);
    setActiveTab(e);
    setPage(1);
    setAppointmentsList([]);
    tabsArr.forEach((data) => {
      if (data.key === e) {
        setStatus(data?.tabName);
      }
    });
    setLoader(true);
  };

  const showDeclineAppointmentModal = (e, visible) => {
    e.stopPropagation();
    setVisible(visible);
  };

  useEffect(() => {
    getAppointMentList(initial);
  }, [status, page]);

  const getAppointMentList = async (initialRender) => {
    setLoader(true);
    axiosInstance
      .get(`business/appointmentList?page=${page}&pageLimit=10&status=${status}`)
      .then((res) => {
        if (res.data?.output?.list.length > 0) {
          setInitial(false);

          const updated = initialRender ? res.data?.output?.list : [...appointmentsList, ...res.data?.output?.list];
          setAppointmentsList(updated);
        } else if (res.data?.output?.list?.length === 0 && page === 1) {
          setAppointmentsList(res.data?.output?.list);
        }
        sethasMore(res.data?.output?.hasResult);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        message.error(err?.data?.error?.message);
      });
  };

  // same for both accept and decline appointment
  const acceptorDeclineHandler = async (id, isApproved, startAppointment, closeAppointment) => {
    setLoader(true);
    const payload = {
      appointmentId: id,
      isApproved, // isApproved true for approving
      startAppointment,
      closeAppointment,
    };
    try {
      await axiosInstance.post("/business/changeAppointmentStatus", payload).then((res) => {
        const filteredList = appointmentsList.filter((item) => item._id !== id);
        setAppointmentsList(filteredList);
      });

      setLoader(false);
    } catch (error) {
      setLoader(false);
      message.error(error?.data?.error?.message);

      if (error?.data?.error?.message === "Appointment has already cancelled") {
        const filteredList = appointmentsList.filter((item) => item._id !== id);
        setAppointmentsList(filteredList);
      }
    }
  };

  function formatISO8601Time(iso8601String) {
    // Parse the ISO 8601 string into a Date object
    const time = iso8601String.split("T")[1]?.split(":");

    // Get the local time components
    const hour = time[0];
    const minute = time[1];

    // Determine if it's AM or PM
    const period = Number(hour) >= 12 ? "PM" : "AM";

    // Convert the hour to 12-hour format
    const hour12 = Number(hour) % 12 || 12; // Ensure 12:00 PM instead of 0:00 PM

    // Format the time as "9:00 AM" or "9:00 PM"
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);

      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (hasMore) {
          setPage((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <Layout>
      <div className="row appointment-dashboard">
        <div className="col-12">
          <Tabs activeKey={activeTab} onChange={handleTabChange} className="appointment-tab">
            {tabsArr.map((tab) => {
              return (
                <>
                  <Tabs.TabPane tab={tab.tabName} key={tab.key}>
                    <div className="appointments-listing" onScroll={onScroll} ref={listInnerRef}>
                      <SpinWrapper loader={Loader}>
                        {appointmentsList.length > 0 ? (
                          appointmentsList?.map((i) => {
                            return (
                              <>
                                <ProviderAppointmentCard
                                  i={i}
                                  showDeclineAppointmentModal={showDeclineAppointmentModal}
                                  visible={visible}
                                  setVisible={setVisible}
                                  acceptAppointment={acceptAppointment}
                                  setAcceptAppointment={setAcceptAppointment}
                                  acceptorDeclineHandler={acceptorDeclineHandler}
                                  activeTab={activeTab}
                                  formatISO8601Time={formatISO8601Time}
                                  tabName={tab.tabName}
                                />
                              </>
                            );
                          })
                        ) : (
                          <center>
                            <h3 style={{ marginTop: "30vh" }}>{!Loader && "No Appointments"}</h3>
                          </center>
                        )}
                      </SpinWrapper>
                    </div>
                  </Tabs.TabPane>
                </>
              );
            })}
          </Tabs>
        </div>
      </div>
    </Layout>
  );
}

const ProviderAppointmentCard = ({
  i,
  showDeclineAppointmentModal,
  visible,
  acceptorDeclineHandler,
  setAcceptAppointment,
  activeTab,
  formatISO8601Time,
  tabName,
}) => {
  const navigate = useNavigate();
  const acceptButtonRef = useRef();
  const declineButtonRef = useRef();

  return (
    <>
      <Row
        className={i?.status == "Ongoing" ? "appointment-wrapper-ongoing" : "appointment-wrapper"}
        onClick={(event) => {
          const acceptClicked = acceptButtonRef.current && acceptButtonRef.current.contains(event.target);
          const declineClicked = declineButtonRef.current && declineButtonRef.current.contains(event.target);

          if (!(acceptClicked || declineClicked)) {
            navigate(`/business-provider/appointments/appointment-details/${i._id}`, { state: { tabName } });
          }
        }}
      >
        {/* appointment-tab */}
        <Col className="appointment-col" lg={24} md={24} xs={24}>
          <h5 className="person-name">
            {i?.userDetails?.firstName} {i?.userDetails?.lastName}
            {activeTab != 1 && (
              <>
                <span className="opacity-50"> | </span>
                <span className="opacity-50">
                  {i?.userDetails?.countryCode}{" "}
                  {(i?.userDetails?.phoneNumber)?.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")}
                </span>
              </>
            )}
          </h5>
          <p className="service-fee">
            <span>Service Fee: ${i?.selectedServices ? i?.serviceFee : 0}.00</span>
          </p>
        </Col>

        {i?.status == "Pending" ? (
          ""
        ) : (
          <Col
            xs={24}
            md={12}
            className={
              i?.status === "Canceled" || i?.status === "Expired"
                ? "d-flex justify-content-end status-tag-col-canceled"
                : i?.status === "Ongoing"
                ? "d-flex justify-content-end status-tag-col-ongoing"
                : "d-flex justify-content-end status-tag-col"
            }
          >
            <div className="common-tag-status status-tag">{i?.status}</div>
          </Col>
        )}
        <Col
          lg={8}
          md={12}
          xs={24}
          className="d-flex flex-column appointment-col"
          style={{ borderRight: "1px solid #E7E8EA" }}
        >
          <h6 className="card-title pb-3">Services</h6>
          <div className="services-wrapper d-flex " style={{ overflowX: "scroll" }}>
            {i.selectedServices
              ? i?.selectedServices.map((services) => {
                  return (
                    <>
                      <div
                        className="d-flex align-items-center justify-content-between service-wrapper__child "
                        style={{ gap: "18px", width: "fit-content" }}
                      >
                        <img className="img-fluid-services" src={services?.imgUrl} alt="service-image" />
                        <h5 className="card-value" style={{ width: "fit-content" }}>
                          {services?.title}
                        </h5>
                      </div>
                    </>
                  );
                })
              : ""}
          </div>
        </Col>
        {/*FOR DESKTOP SERVICE CATEGORY*/}
        <Col
          lg={12}
          md={12}
          xs={24}
          xl={4}
          className="d-flex appointment-col flex-column d-none d-sm-none d-md-block d-xl-block d-lg-block px-3"
          style={{ borderRight: "1px solid #E7E8EA" }}
        >
          <>
            <h6 className="card-title">Service Category</h6>
            <h5 className="card-value mt-4 p-0">{i?.selectedServices && i?.selectedServices[0]?.categoryName}</h5>
          </>
        </Col>
        <Col lg={12} md={24} xl={6} xs={24} className="d-flex appointment-col flex-column px-3 ">
          <h6 className="card-title">Date and Time</h6>

          <div className="date-time-wrapper d-flex ">
            <div className="date-wrapper d-flex align-items-center mt-2">
              <img alt="" src="/assets/icons/calendar.svg" />
              <h5 className="card-value" style={{ width: "max-content" }}>
                {dayjs(i?.appointmentDate).format("MMMM D, YYYY")}
              </h5>
            </div>
            <div className="time-wrapper d-flex align-items-center mt-2">
              <img alt="" src="/assets/icons/clock.svg" />
              <h5 className="card-value" style={{ width: "max-content" }}>
                {formatISO8601Time(i?.appointmentDate)}
              </h5>
            </div>
          </div>
        </Col>
        {/*FOR MOBILE SERVICE CATEGORY*/}
        <Col
          lg={12}
          md={12}
          xs={24}
          xl={4}
          className="d-flex appointment-col flex-column d-block d-sm-block d-md-none d-xl-none d-lg-none"
        >
          <h6 className="card-title">Service Category</h6>
          <h5 className="card-value mt-10-custom"> {i?.selectedServices && i?.selectedServices[0]?.categoryName}</h5>
        </Col>
        {activeTab == 1 && (
          <Col
            lg={12}
            md={24}
            xl={6}
            xs={24}
            className="d-flex justify-content-end align-items-center action-btn-col"
          >
            <ul className="list-inline mb-0">
              <li className="list-inline-item" ref={declineButtonRef}>
                <Button className="common-secondary-btn" onClick={(e) => showDeclineAppointmentModal(e, true)}>
                  Decline
                </Button>
              </li>
              <li className="list-inline-item" ref={acceptButtonRef}>
                <Button className="common-btn ml-3" onClick={(e) => acceptorDeclineHandler(i._id, true)}>
                  Accept
                </Button>
              </li>
            </ul>
          </Col>
        )}
      </Row>

      <DeclineAppointmentModal
        headingText={"Are you sure you want to decline this appointment?"}
        visible={visible}
        onClose={(e) => showDeclineAppointmentModal(e, false)}
        id={i._id}
        setAcceptAppointment={setAcceptAppointment}
        acceptorDeclineHandler={acceptorDeclineHandler}
      />
    </>
  );
};
